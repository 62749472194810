import {noop} from 'lodash'
import {componentBlockMapping, testCatalogComponentMapping} from 'testCatalogComponentMapping'
import {gotoCartPage} from 'wrapper/CartContextAuthWrapper'

import {ContentfulClientConfig, isServer, NucleobaseConfig} from '@invitae/nucleobase'
import {NucleobaseShellNucleobaseConfig} from '@invitae/nucleobase/dist/NucleobaseShell/NucleobaseShell'
import {IFeatureFlags, IPassedContext as CartConfig} from '@invitae/physician-cart/dist/context'

import {cleanUpUrlFromParams} from 'components/MainCategory/utils'
import {FEATURE_FLAGS} from 'constants/featureFlags'

export const contentfulClientCredentials: ContentfulClientConfig = {
  accessToken: process.env.INVITAE_CONTENTFUL_CONTENT_DELIVERY_API_KEY || 'Provide contentful delivery API key',
  environment: process.env.INVITAE_CONTENTFUL_ENVIRONMENT || 'Provide Contentful environment',
  space: process.env.INVITAE_CONTENTFUL_SPACE || 'Provider Contentful space',
}

export const analyticsConfig = ({currentPath}: {currentPath: string}) => ({
  currentPageUrl: cleanUpUrlFromParams(currentPath),
  previousPageUrl: typeof document !== 'undefined' ? document.referrer : '',
  ['Site location']: currentPath,
})

// @deprecated
export const launchDarklyConfig = (isUserAuthenticated: boolean) => ({
  apiEndpoint: `${process.env.STARGATE_HOST}/feature-flags` || '',
  flagsKeysList: FEATURE_FLAGS,
  isUserAuthenticated,
})

export const nucleobaseConfig: NucleobaseConfig = {
  authAPI: process.env.AUTH_API_HOST || '',
  searchAPI: process.env.SEARCH_API || '',
}

export const headerConfig = (props: {currentPath: string}) => {
  const {currentPath} = props

  // To avoid page duplicity due to canonical pathname we remove the final slash from path
  const pathWithSlash = currentPath.startsWith('/') ? currentPath : `/${currentPath}`
  const path = pathWithSlash !== '/' ? pathWithSlash : ''

  const testCatalogPath = `${process.env.INVITAE_BASE_URL}${path}`

  return {
    canonicalPathname: cleanUpUrlFromParams(!isServer() ? window.location.href : testCatalogPath),
    facebookVerification: process.env.FACEBOOK_VERIFICATION,
  }
}
export const shellNucleobaseConfig: NucleobaseShellNucleobaseConfig = {
  componentBlockMap: componentBlockMapping,
  context: {
    ...nucleobaseConfig,
  },
  customComponentsMap: testCatalogComponentMapping,
}

export const globalTextCopiesConfig = {
  enableClientSideRequest: false,
}

export const cartConfig = ({featureFlags}: {featureFlags: IFeatureFlags}): CartConfig => ({
  apiParameters: {
    basePath: process.env.CART_BASE_PATH,
  },
  featureFlags,
  gotoCartPage,
  goToSignInPage: noop,
})

const NIPS_PRODUCT_TYPE = 'NIPS'
const CARRIER_PRODUCT_TYPE = 'Carrier'

// https://invitae.jira.com/browse/CELT-1915
export const DEPRECATED_PRODUCT_TYPES = [NIPS_PRODUCT_TYPE, CARRIER_PRODUCT_TYPE]
