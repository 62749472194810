import {useQuery} from '@tanstack/react-query'

import TestCatalogAPI from 'api/testCatalogApi'

export function usePanelQuery(panelCode: string) {
  const testCatalogApi = new TestCatalogAPI()
  return useQuery(['getPanel', panelCode], () => testCatalogApi.getPanel(panelCode), {
    enabled: !!panelCode,
  })
}

export function usePanelCardQuery(panelCode: string) {
  const testCatalogApi = new TestCatalogAPI()
  return useQuery(['getPanelCard', panelCode], () => testCatalogApi.getPanelCard(panelCode), {
    enabled: !!panelCode,
  })
}
