import * as React from 'react'
import classnames from 'classnames'
import {PanelCardsItemSelectedContext} from 'wrapper/PanelCardsContext'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {AddToCart} from '@invitae/add-to-cart'
import {NvTooltip} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {CategoryForPanelDetailedPageDto} from '@invitae/stargate'

import GeneListTooltip from 'components/GeneListTooltip'
import {Authenticating} from 'constants/authState'
import {IOptionalTestData, IPanelData} from 'hooks/types'
import useHandlePanelCards, {PanelType} from 'hooks/useHandlePanelCards.hooks'

import PanelCardsItemAddon from './PanelCardsItemAddon'

import styles from './PanelCardItem.module.scss'

export interface IPanelCardsItemProps {
  children?: React.ReactNode
  showGeneCount?: boolean
  primaryPanelData?: IPanelData
  optionalTestsData?: IOptionalTestData[]
}

const ADD_TO_CART_ANALYTICS_EVENT = 'click add to cart from HCP test catalog'

const MAIN_CATEGORY_CONCEPT_TYPE = 'main_category'

const PanelCardsItem: React.FC<IPanelCardsItemProps> = ({
  primaryPanelData,
  optionalTestsData,
  showGeneCount,
  children,
}) => {
  const {
    //state utils
    isAddonProvided,
    totalGenesCount,
    // sectionHeading,
    showAdded,
    //handle select and add to cart
    handleSelectedPanel,
    handleAddButtonClick,
    //main data
    selectedPanels,
    cleanPanelCode,
  } = useHandlePanelCards({
    optionalTestsData,
    primaryPanelData: primaryPanelData ?? {},
    type: PanelType.HUB,
  })

  const {addonOpenedId, setSelectedAddon, sectionHeading} = React.useContext(PanelCardsItemSelectedContext)
  const {authState} = React.useContext(UserAuthStatusContext)
  const {logEvent} = useAnalyticsQueue()
  /*default values */
  const isAddonOpened = addonOpenedId === `${cleanPanelCode}`

  const getPanelCardDataTestId = () => {
    let panelCardDataTestId = isAddonProvided ? 'panel-cards-add-on-item' : 'panel-cards-item'
    if (primaryPanelData?.title?.includes('STAT')) {
      panelCardDataTestId = `${panelCardDataTestId}-STAT`
    }
    if (primaryPanelData?.title?.includes('NIPS')) {
      panelCardDataTestId = `${panelCardDataTestId}-NIPS`
    }
    return panelCardDataTestId
  }

  const geneCountMessage = isAddonProvided
    ? `up to ${totalGenesCount} genes`
    : `${totalGenesCount} ${totalGenesCount > 1 ? 'genes' : 'gene'}`

  //additionally handle addon open/closing for the panel card
  const handleAddClick = React.useCallback(() => {
    logEvent({
      eventName: ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'Panel Name': primaryPanelData?.title,
        section: sectionHeading,
      },
    })
    if (isAddonProvided && !isAddonOpened) {
      //continue to adding panels
      setSelectedAddon(`${cleanPanelCode}`)
    } else {
      handleAddButtonClick()
    }
  }, [primaryPanelData, sectionHeading, logEvent, isAddonProvided, isAddonOpened, handleAddButtonClick, selectedPanels])

  const addToCartButtonText = React.useMemo(() => {
    let buttonText = 'Add to order'
    if (isAddonOpened) {
      buttonText = 'Confirm selection'
    }
    if (showAdded) {
      buttonText = 'Added'
    }
    return buttonText
  }, [isAddonOpened, showAdded])

  const clinicalAreaProperties = React.useMemo(() => {
    const categories =
      primaryPanelData?.categories
        ?.filter((category: CategoryForPanelDetailedPageDto) => category.conceptType === MAIN_CATEGORY_CONCEPT_TYPE)
        .map((category: CategoryForPanelDetailedPageDto) => category.name) ?? []
    if (!primaryPanelData?.title || categories.length === 0) return
    const clinicalAreaProperties: {[key: string]: string} = {}
    categories.forEach((categoryName: string | undefined) => {
      if (primaryPanelData?.title) {
        if (categoryName) {
          clinicalAreaProperties[primaryPanelData.title] = categoryName
        }
      }
    })
    return clinicalAreaProperties
  }, [primaryPanelData?.title, primaryPanelData?.categories])

  return (
    <li className={styles.card} data-testid={getPanelCardDataTestId()}>
      <div
        aria-expanded={isAddonOpened}
        className={classnames(styles.cardMain, {[styles.cardMinMain]: !isAddonOpened})}
      >
        {children ? (
          <>{children}</>
        ) : (
          <p className={styles.cardTitle} data-testid="panel-cards-item-title">
            {primaryPanelData?.title}
          </p>
        )}
        <div className={styles.cardInnerContainer}>
          {showGeneCount && (
            <>
              {totalGenesCount > 0 && (
                <NvTooltip
                  destroyTooltipOnHide
                  limitedWidth={false}
                  overlayContent={
                    <GeneListTooltip
                      optionalTestList={optionalTestsData}
                      primaryPanelData={primaryPanelData ?? {}}
                      selectedPanels={selectedPanels}
                    />
                  }
                  showCloseButtonTop
                  trigger="click"
                >
                  <button
                    aria-label={`${primaryPanelData?.title} - ${geneCountMessage}`}
                    className={classnames('nv-plain-action', styles.cardGeneCount)}
                    data-testid="panel-cards-item-gene-count"
                    type="button"
                  >
                    {isAddonProvided ? (
                      <>
                        up&nbsp;to
                        <br />
                        {totalGenesCount}&nbsp;genes
                      </>
                    ) : (
                      `${totalGenesCount} ${totalGenesCount > 1 ? 'genes' : 'gene'}`
                    )}
                  </button>
                </NvTooltip>
              )}
            </>
          )}
          {!isAddonOpened && (
            <AddToCart
              className={styles.addButton}
              clinicalAreaProperties={clinicalAreaProperties}
              dataTestId="button_panel_add"
              disabled={showAdded}
              onClick={() => !showAdded && handleAddClick()}
              productId={`${cleanPanelCode}`}
              size="medium"
              variant="secondary"
            >
              {showAdded ? 'Added' : 'Add'}
            </AddToCart>
          )}
        </div>
      </div>
      {isAddonOpened && primaryPanelData && (
        <PanelCardsItemAddon
          buttonDisabled={showAdded || authState === Authenticating}
          buttonText={addToCartButtonText}
          handleMultiplePanelAdd={handleAddClick}
          handleSelectedPanel={handleSelectedPanel}
          optionalTestList={optionalTestsData}
          primaryPanelData={primaryPanelData}
          selectedPanels={selectedPanels}
        />
      )}
    </li>
  )
}

export default PanelCardsItem
