import {ContentfulModels} from '@invitae/contentful-types'
import {contentfulComponentsMap, NucleobaseComponent} from '@invitae/nucleobase'
import {ComponentBlockSectionInfo} from '@invitae/nucleobase'

import CategoryTabSection from 'components/CategoryTabSection'
import ConceivingComponentBlock from 'components/ConceivingComponentBlock/ConceivingComponentBlock'
import GCSTComponentBlock from 'components/GCSTComponentBlock/GCSTComponentBlock'
import HubHero from 'components/HubHero/HubHero'
import ContentfulPanelCardsSection from 'components/PanelCardsSection/ContentfulPanelCardsSection'
import PanelCardsItem from 'components/PanelCardsSection/PanelCardsItem'
import PediatricAndRareDiseasesComponentBlock from 'components/PediatricAndRareDiseasesComponentBlock/PediatricAndRareDiseasesComponentBlock'

import PregnancyComponentBlock from './components/PregnancyComponentBlock/PregnancyComponentBlock'

export const testCatalogComponentMapping: Record<string, NucleobaseComponent<any>> = {
  ...contentfulComponentsMap,
  testCatalogMainCategorySection: CategoryTabSection,
  [ContentfulModels.panelCardsSection]: ContentfulPanelCardsSection,
  [ContentfulModels.panelCardsItem]: PanelCardsItem as unknown as NucleobaseComponent<any> & Record<string, any>,
}

export const componentBlockMapping: Record<string, ComponentBlockSectionInfo> = {
  ConceivingComponentBlock: {Component: ConceivingComponentBlock, isSection: true},
  GCSTComponentBlock: {Component: GCSTComponentBlock, isSection: true},
  HubHero: {Component: HubHero, isSection: true},
  PediatricAndRareDiseasesComponentBlock: {Component: PediatricAndRareDiseasesComponentBlock, isSection: true},
  PregnancyComponentBlock: {Component: PregnancyComponentBlock, isSection: true},
}
