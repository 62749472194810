import * as React from 'react'
import {useRouter} from 'next/router'

import {ITestCatalogMainCategorySectionFields} from '@invitae/contentful-types'
import {NvTab, NvTabs} from '@invitae/ids-react'
import {isServer, NucleobaseComponent, ToggleContext, useAnalyticsQueue} from '@invitae/nucleobase'

import Breadcrumbs from 'components/Breadcrumbs'
import {cleanUpUrlFromParams} from 'components/MainCategory/utils'
import {VIEW_MAIN_CATEGORY_PAGE} from 'constants/analytics'
import {TABS_QUERY_NAME} from 'constants/links'

import GenesTab from './GenesTab'
import PanelTab from './PanelTab'

import styles from './CategoryTabSection.module.scss'

export enum CategoryTabs {
  Tests = 'Tests',
  Genes = 'Individual genes',
}

const CategoryTabSection: NucleobaseComponent<ITestCatalogMainCategorySectionFields> = props => {
  const {logEvent} = useAnalyticsQueue()
  const router = useRouter()

  const {linkClickLogEvent} = useAnalyticsQueue()
  const {fields} = props
  const {frequentlyOrderedPanels, frequentlyOrderedGenes, hideIndividualGenesTab} = fields
  const {cxEnableTestCatalogOrderingTags} = React.useContext(ToggleContext)

  const onRoutePush = React.useCallback(
    (path: string) => {
      router.push(path, undefined, {shallow: true})
    },
    [router],
  )

  const [pathname, searchParam] = React.useMemo(() => router.asPath.split('?'), [router.asPath])

  const onTabChange = React.useCallback(
    (tabId: string, {lastTabId}: any) => {
      linkClickLogEvent({
        currentPage: 'test catalog main category page',
        eventProperties: {
          'Current Tab': lastTabId,
          'Destination Tab': tabId,
        },
        linkName: 'change tab',
      })
    },
    [linkClickLogEvent],
  )

  React.useEffect(() => {
    if (!isServer() && cxEnableTestCatalogOrderingTags !== undefined) {
      logEvent({
        eventName: VIEW_MAIN_CATEGORY_PAGE,
        eventProperties: {
          currentPage: cleanUpUrlFromParams(router.asPath),
          cxEnableTestCatalogOrderingTags: cxEnableTestCatalogOrderingTags,
          referrer: document.referrer,
        },
      })
    }
  }, [isServer, logEvent, cxEnableTestCatalogOrderingTags])

  return (
    <>
      <Breadcrumbs isGray />
      <div className={styles.categoryTabSectionRoot}>
        <NvTabs
          defaultTabId={CategoryTabs.Tests}
          id={TABS_QUERY_NAME}
          onRoutePush={onRoutePush}
          onTabChange={onTabChange}
          pathname={pathname}
          searchParam={searchParam ? `?${searchParam}` : ''}
          withSearchParamsControl
        >
          <NvTab tabId={CategoryTabs.Tests} titleText={CategoryTabs.Tests}>
            <PanelTab frequentlyOrderedPanels={frequentlyOrderedPanels} />
          </NvTab>
          {!hideIndividualGenesTab && (
            <NvTab tabId={CategoryTabs.Genes} titleText={CategoryTabs.Genes}>
              <GenesTab
                categoryId={fields.mainCategoryPrCode}
                categoryName={fields.internalName}
                frequentlyOrderedGeneIds={frequentlyOrderedGenes}
              />
            </NvTab>
          )}
        </NvTabs>
      </div>
    </>
  )
}

export default CategoryTabSection
